import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react"

import { ImageQueryQuery } from "../../graphql-types"

const P2gsign: React.FC = () => {
    const data: ImageQueryQuery = useStaticQuery(graphql`query p2gQuery {
  placeholderImage: file(relativePath: {eq: "p2gsign.png"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
}
`)
  return (
    <GatsbyImage image={data.placeholderImage?.childImageSharp?.gatsbyImageData} alt="Park To Give Sign" />
  )
}

export default P2gsign