import React, { useState }  from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { config } from 'react-transition-group'
import { Api } from '../api'

const styles = require('../styles/contactUs.module.css')

const ContactUs: React.FC = () => {
    const [contactData,setContactData] = useState({
        emailAddr:"",
        firstName:"",
        lastName:"",
        phoneNumber:"",
        areaCode:"",
        firstThree:"",
        lastFour:"",
        message:""
    });
    const [submitted,setSubmitted] = useState(false);
    const [submissionComplete,setSubmissionComplete] = useState(false);
    const [submissionResultMessage,setsubmissionResultMessage] = useState('');

    

    function submit():void {
        setSubmitted(true);
        setContactData({...contactData,phoneNumber: contactData.areaCode + contactData.firstThree + contactData.lastFour});
        Api.contactUs(contactData).then( r => {
            if(r.ok) {
                setsubmissionResultMessage('Thanks for contacting us. We will be back in touch shortly.');
            }
            else{
                setsubmissionResultMessage('There was a problem submitting the the contact form, please try again later.');
            }
            setSubmissionComplete(true);
        })
    }

    function emailChanged(e: React.ChangeEvent<HTMLInputElement> ):void {
        setContactData({...contactData,emailAddr: e.target.value});
    }
    function lastNameChanged(e: React.ChangeEvent<HTMLInputElement> ):void {
        setContactData({...contactData,lastName: e.target.value});
    }
    function firstNameChanged(e: React.ChangeEvent<HTMLInputElement> ):void {
        setContactData({...contactData,firstName: e.target.value});
    }
    function messageChanged(e: React.ChangeEvent<HTMLInputElement> ):void {
        setContactData({...contactData,message: e.target.value});
    }

    function areaCodeChanged(e: React.ChangeEvent<HTMLInputElement> ):void {
        setContactData({...contactData,areaCode: e.target.value});
    }

    function firstThreeChanged(e: React.ChangeEvent<HTMLInputElement> ):void {
        setContactData({...contactData,firstThree: e.target.value});
    }

    function lastFourChanged(e: React.ChangeEvent<HTMLInputElement> ):void {
        setContactData({...contactData,lastFour: e.target.value});
    }



    return (
        <div>
          
            <p>Use the form below to contact us. We welcome your questions, comments, and suggestions.
            Do you have parking spaces that you think would fit the <b>Park to Give</b> business model? We’d love to hear from you, too</p>
            <p hidden={!submissionComplete}>{submissionResultMessage}</p>

            <div className={styles.inputFields}>
                <form >
                <TextField disabled={submitted} onChange={firstNameChanged} value={contactData.firstName} variant="filled" label="First Name" style={{ width: "45%", margin: "0px 20px 0px 0px" }} inputProps={{ name: "firstName", "aria-required": true }} />
                <TextField disabled={submitted} onChange={lastNameChanged} value={contactData.lastName} variant="filled" label="Last Name" style={{ width: "45%", margin: "0px 0px 0px 20px" }} inputProps={{ name: "lastName", "aria-required": true }} />
                <TextField disabled={submitted} onChange={emailChanged} value={contactData.emailAddr} variant="filled" label="Email Address" style={{ width: "95%", margin: "10px auto 0px" }} inputProps={{ name: "emailAddr", "aria-required": true }} />
                <TextField disabled={submitted} onChange={areaCodeChanged} value={contactData.areaCode} variant="filled" label="(###)" style={{ width: "70px", margin: "10px 10px 0px 0px" }} inputProps={{ maxLength: 3, name: "areaCode" }} />
                <TextField disabled={submitted} onChange={firstThreeChanged} value={contactData.firstThree} variant="filled" label="###" style={{ width: "70px", margin: "10px 10px" }} inputProps={{ maxLength: 3, name: "firstThree" }} />
                <TextField disabled={submitted} onChange={lastFourChanged} value={contactData.lastFour} variant="filled" label="####" style={{ width: "70px", margin: "10px 10px" }} inputProps={{ maxLength: 4, name: "lastFour" }} />
                <TextField disabled={submitted} onChange={messageChanged} value={contactData.message} variant="filled" label="How Can We Help?" multiline rows="7" style={{ width: "95%", margin: "0px auto" }} inputProps={{ name: "message", "aria-required": true }} />
                <Button disabled={submitted} onClick={submit}  style={{ background: "#08f", color: "white", margin: "30px auto", padding: "10px 30px" }}>Submit</Button>
                </form>
            </div>
        </div>
    )
}

export default ContactUs;