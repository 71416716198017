import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import P2gsign from "../components/p2gsign"
import Button from '@material-ui/core/Button'
import Subscribe from "../components/subscribe"
import ContactUs from "../components/contactUs"


const classNames = require('classname')
const styles = require('../styles/home.module.css')

const HomePage: React.FC = () => (
  <Layout>
    <SEO title="Home" />

    <div className={styles.container}>
      <div className={classNames({ [styles.main]: true, [styles.mainRaised]: true })}>
        <div className={styles.mobileFlex}>
          <div className={styles.whoWeAre}>
            <h1>Who We Are</h1>

            <p><b>Park to Give</b> is the inspiration of two Gamecock alumni with the goal of
    making parking better for residents and guests of our Capital City – and at the same time, impact our local community for good. </p>
            <p>What makes parking with us different? Well, a few things:</p>
            <ul>
              <li>All parking spaces are provided in partnership with local businesses who care about our community.
        These businesses use their parking during the day and make their spaces available for public paid parking during evenings and weekends.</li>
              <li>All parking is paid for using our mobile platform with support for Apple and Google Pay and all major credit cards.</li>
              <li>And most importantly, all net proceeds go to support the United Way of the Midlands and their work in making a difference in our local community.</li>
            </ul>

            <Button size="large" style={{ margin: "20px", padding: "20px 40px", background: "#08f", color: "white" }} href="/about/">How it works</Button>
            <br />
            <br />
            <br />
            <br />
            <h1>What We Do</h1>
            <p><b>Park to Give</b> utilizes parking spaces that are owned by businesses and reserved during the week for employees,
            and makes them available as paid parking during the evenings and weekends. </p>
            <br />
            <br />
            <br />
            <h1>Find Our Spaces</h1>
            <p>Our spaces are located in the Vista. Look for our signs so you can <b>Park to Give</b>!</p>
          </div>
          <div className={styles.howDoI}>
            <h2>How Do I Park to Give?</h2>
            <div style={{ width: "200px", margin: "10px auto 20px" }}>
              <P2gsign />
            </div>
            <div style={{ color: "#08f", fontWeight: "bold" }}>
              <ol>
                <li>Look for our signs and park during Pay to Park hours</li>
                <li>Scan the QR code or go to www.park.gives</li>
                <li>Pay using Apple or Google Pay or any major credit card</li>
              </ol>
            </div>
          </div>
        </div>
        <div className={styles.space75}>

        </div>

        <div className={styles.quoteBox}>
          <hr />
          <p className={styles.quoteText}>
            "We make a living by what we get. We make a life by what we give."
      </p>
          <p className={styles.quoteCitation}>
            — WINSTON CHURCHILL
      </p>
          <hr />
        </div>

        <div className={styles.space75}>
        </div>
        <p className={styles.letsChatTitle}>Let's Chat.</p>
        <div className={styles.letsChat}>
          <ContactUs/>
        </div>

        <div className={styles.space75}>
        </div>

        <Subscribe />

      </div>


    </div>

  </Layout>

)

export default HomePage
